import styled from "styled-components";

const Logo = styled.img`
  height: 55%;
  filter: brightness(0) invert(1);
  margin-top: 6%;
  margin-left: 15%;
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Header = styled.header`
  position: fixed;
  height: 7%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  padding: 3px;
  background-color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #ffffff;
  margin: 0;
  text-align: right;
`;

const MenuButton = styled.button`
  margin-top: 6%;
  margin-left: 5%;
  font-size: 100%;
  padding: 3% 4% 2% 4%;
  border: 1px solid white;
  border-radius: 5px;
  align-content: center;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  height: 55%;
`;

const RightHeaderWrapper = styled.div`
  margin-left: auto;
  margin-right: 3%;
`;

const LoginButton = styled.button`
  margin-top: 25%;
  font-size: 100%;
  padding: 2% 25% 4% 25%;
  border: 1px solid white;
  border-radius: 5px;
  border-radius: 4px;
  background-color: #333;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  height: 55%;
`;

const styles = {
  Logo,
  Header,
  LeftHeaderWrapper,
  RightHeaderWrapper,
  LoginButton,
  Title,
  MenuButton,
};

export default styles;
