import React from "react";
import { ProductTrackerComponent } from "../../components/productTrackerComponent/productTrackerComponent";

const ProductTrackerPage: React.FC = () => {
  const entity_id = "935c2c34-c2a5-4964-a3f1-99f8d4564d1d";

  return (
    <div>
      <ProductTrackerComponent entity_id={entity_id} />
    </div>
  );
};

export default ProductTrackerPage;
