import React, { useEffect } from "react";
import Spinner from "../../components/spinner/spinner";
import gs from "../globalStyles.styles";
import s from "./redirectPage.styles";

const RedirectPage: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.open("https://api-documentation-web-app.front.icekube.ics.cloud/");
    }, 1000);
  }, []);

  return (
    <gs.PageContainer>
      <gs.Container>
        <gs.Title>Redirecting...</gs.Title>
        <Spinner></Spinner>
        <s.Message>The new page will open in a new tab</s.Message>
      </gs.Container>
    </gs.PageContainer>
  );
};

export default RedirectPage;
