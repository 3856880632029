import React from "react";
import s from "./header.styles";
import logo from "../../resources/ingenuity-1.svg";
import { FiMenu } from "react-icons/fi";

interface HeaderProps {
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = ({ setIsSidebarOpen }) => {
  const handleToggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
      <s.Header>
        <s.LeftHeaderWrapper>
          <s.MenuButton data-testid="menu-button" onClick={handleToggleSidebar}>
            <FiMenu />
          </s.MenuButton>
          <s.Logo src={logo} alt="Logo" />
        </s.LeftHeaderWrapper>
        <s.RightHeaderWrapper>
        </s.RightHeaderWrapper>
      </s.Header>
  );
};

export default Header;
