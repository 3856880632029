import s from "./sideBar.styles";
import { GrClose } from "react-icons/gr";

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SideBar({ isOpen, setIsOpen }: SideBarProps) {
  const handleToggleSidebar = () => {
    setIsOpen(false);
  };

  return isOpen ? (
    <s.MenuBar>
      <s.CloseButton onClick={handleToggleSidebar}>
        <GrClose />
      </s.CloseButton>
      <s.MenuLink to="/">Status Page</s.MenuLink>
      <s.MenuLink to="/documentation">API Documentation</s.MenuLink>
      <s.MenuLink to="/product-tracker">Product Tracker</s.MenuLink>
    </s.MenuBar>
  ) : null;
}

export default SideBar;
