import React, { useEffect, useState } from "react";

interface Props {
  entity_id: string;
}
interface Event {
  id: string;
  entity_id: string;
  entity_type: string;
  operation: string;
  entity_snapshot: string;
  client_id: string;
  created_at: string;
  statemachine_version?: string;
  checksum?: string;
}

interface EventStatus {
  id: string;
  event_id: string;
  consumer_id: string;
  status: string;
  created_at: string;
  client_id: string;
}
export const ProductTrackerComponent: React.FC<Props> = ({ entity_id }) => {
  const [eventList, setEventList] = useState<[Event]>();
  const [currentEvent, setCurrentEvent] = useState<Event>();
  const [statusList, setStatusList] = useState<[EventStatus]>();

  useEffect(() => {
    if (!process.env.REACT_APP_PRODUCT_TRACKER) {
      console.error(
        "REACT_APP_PRODUCT_TRACKER environment variable is not set."
      );
      return;
    }

    const client_id = "79fe5e27-3b01-4022-94cc-bea3d7d8a0ea";
    const url = `/product_tracker/entity/${entity_id}?client_id=${client_id}`;

    async function fetchData() {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            admin_secret: "secret123",
            Accept: "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log("Raw JSON data:", jsonData); // Log the raw JSON data

        // Parsing the event IDs as bigint
        const parsedEventList: [Event] = jsonData.map((event: any) => ({
          ...event,
          id: BigInt(event.id),
        }));

        console.log(parsedEventList[0].id);
        setEventList(parsedEventList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [entity_id]);

  useEffect(() => {
    if (eventList && eventList[0]) {
      setCurrentEvent(eventList[0]);
      get_event_status(
        eventList[0].id.toString(),
        "79fe5e27-3b01-4022-94cc-bea3d7d8a0ea"
      );
    }
  }, [eventList]);

  const get_event_status = (event_id: string, client_id: string) => {
    async function fetchStatus() {
      try {
        const response = await fetch(
          `/product_tracker/event_status/${event_id}?client_id=${client_id}`,
          {
            method: "GET",
            headers: {
              admin_secret: "secret123",
              Accept: "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setStatusList(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchStatus();
  };

  console.log(eventList);
  return (
    <div>
      <h2>Product Tracker</h2>
      <h2>Product: {entity_id}</h2>
      <h2> CURRENT PRODUCT</h2>
      <p> {currentEvent?.entity_snapshot}</p>
      <h2>List of Event Statuses</h2>
      <ul>
        {statusList?.map((status) => (
          <li key={status.id}>
            <p>Event ID: {status.event_id}</p>
            <p>Consumer ID: {status.consumer_id}</p>
            <p>Status: {status.status}</p>
            <p>Created At: {status.created_at}</p>
            <p>Client ID: {status.client_id}</p>
          </li>
        ))}
      </ul>
      <table>
        <thead>
          <tr>
            <th>Operation</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {eventList?.map((event) => (
            <tr key={event.id.toString()}>
              <td>{event.operation}</td>
              <td>{event.created_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
