import React from "react";
import "./App.css";
import Header from "./components/header/header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HealthCheckPage from "./pages/healthCheck/healthCheck";
import s from "./App.styles";
import RedirectPage from "./pages/redirectPage/redirectPage";
import SideBar from "./components/sideBar/sideBar";
import { HealthCheckProvider } from "./contexts/healthCheckContext";
import ProductTrackerPage from "./pages/productTrackerPage/productTrackerPage";

const App: React.FC = () => {

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  return (
      <Router>
        <div>
          <Header setIsSidebarOpen={setIsSidebarOpen} />
          <s.AppContainer>
            <s.SidebarContainer isOpen={isSidebarOpen} >
            {isSidebarOpen && <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />}
            </s.SidebarContainer>
            <HealthCheckProvider>
            <s.ContentContainer>
              <Routes>
                <Route path="/" element={<HealthCheckPage />}
 />

                <Route
                  path="/documentation"
                  element={<RedirectPage />}
                />
                <Route
                  path="/product-tracker"
                  element={<ProductTrackerPage />}
                />
              </Routes>
            </s.ContentContainer>
            </HealthCheckProvider>

          </s.AppContainer>
        </div>
      </Router>
  );
};

export default App;