import styled from "styled-components";

interface GridProps {
  itemCount: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

export const Section = styled.div`
  margin-bottom: 40px;
  width: 70%;
`;

export const SectionTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ itemCount }) =>
    itemCount > 1 ? "repeat(2, minmax(400px, 1fr))" : "1fr"};
  grid-gap: 10px;
  justify-items: center;
`;

export const HealthCheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const styles = { Container, Section, SectionTitle, HealthCheckWrapper, Grid };

export default styles;
