import React, { useEffect, useState } from "react";
import { getStatusFromAll, HealthCheckStatus, useHealthCheck } from "../../../contexts/healthCheckContext";
import s from "./healthCheckComponent.styles";
import gs from "../../../pages/globalStyles.styles"
import HealthStatus from "../healthStatus";
import Grid from "@mui/material/Grid";

const getStatusIcon = (healthStatus: HealthStatus) => {
  if (healthStatus === HealthStatus.Degraded) {
    return (
      <s.StatusIconCircle style={{backgroundColor: "orange"}}>
        <s.ServiceDownIcon></s.ServiceDownIcon>
      </s.StatusIconCircle>
    );
  } 
  else if (healthStatus === HealthStatus.Down) {
    return (
      <s.StatusIconCircle style={{backgroundColor: "red"}}>
        <s.ServiceDownIcon></s.ServiceDownIcon>
      </s.StatusIconCircle>
    );
  } 
  else if (healthStatus === HealthStatus.Healthy) {
    return (
      <s.StatusIconCircle style={{backgroundColor: "limegreen"}}>
        <s.ServiceUpIcon></s.ServiceUpIcon>
      </s.StatusIconCircle>
    );
    
};
}

export const HealthCheckComponent: React.FC = () => {

  const healthStatuses = useHealthCheck();
  const [allHealthStatus, setAllHealthStatus] = useState<HealthStatus>(getStatusFromAll(healthStatuses));

  useEffect(()=>{
    setAllHealthStatus(getStatusFromAll(healthStatuses));
  }, [healthStatuses]);

  return (
    <s.HealthStatusContainer healthStatus={allHealthStatus}>
      <gs.Subtitle style={{color: "white"}}>
        {getStatusIcon(allHealthStatus)}
        {allHealthStatus === HealthStatus.Degraded
          ? "Service Degraded"
          : allHealthStatus === HealthStatus.Healthy
          ? "All Systems Operational"
          : "API Outage"}
      </gs.Subtitle>
    </s.HealthStatusContainer>
  );
};


interface HealthCheckTableItemProps {
  api: string;
  healthStatusWithTimestamp: HealthCheckStatus;
}

const HealthCheckTableItem: React.FC<HealthCheckTableItemProps> = ({api, healthStatusWithTimestamp}) => {

  const getMessage = (healthStatus: HealthStatus) => {
    if(healthStatus===HealthStatus.Healthy){
      return "Normal";
    }else if(healthStatus===HealthStatus.Degraded){
      return "Degraded";
    }else if(healthStatus===HealthStatus.Down){
      return "Outage";
    }
  }

  return <s.HealthCheckTableItemContainer>
    <s.HealthCheckTableItemLeftContainer>
      <gs.Subtitle style={{margin: "0", marginRight: "auto"}}>{api}</gs.Subtitle>
      <gs.Text style={{margin: "0", marginRight:"auto", color: "lightgray"}}>{getMessage(healthStatusWithTimestamp.healthStatus)}</gs.Text>
      <gs.Text style={{margin: "0", marginRight:"auto", color: "lightgray"}}>{healthStatusWithTimestamp.timestamp}</gs.Text>
    </s.HealthCheckTableItemLeftContainer>
    <p>{getStatusIcon(healthStatusWithTimestamp.healthStatus)}</p>
  </s.HealthCheckTableItemContainer>;
}

export const HealthCheckTableComponent: React.FC = () => {

  const healthCheckStatuses = useHealthCheck();
  const numCols = Object.keys(healthCheckStatuses).length %2 === 0 ? 2 : 1;
  
  
  return <s.HealthStatusTableContainer>
    <Grid container spacing={0}>
      {
        Object.keys(healthCheckStatuses).map((key: string)=>
          (<Grid item xs={12} sm={12} md={numCols===2 ? 6 : 12} lg={numCols===2 ? 6 : 12}>
            <HealthCheckTableItem api={key} healthStatusWithTimestamp={healthCheckStatuses[key]}/>
          </Grid>))
      }
    </Grid>
  </s.HealthStatusTableContainer>;
}
